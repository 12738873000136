import React from 'react';
import {Helmet} from 'react-helmet';
import {Link, graphql} from 'gatsby';
import Layout from '../templates/layout';
import {TagCountQuery} from '../types/graphql-types';

const Tags = ({
  data: {
    allMarkdownRemark: {group},
    site,
  },
}: {
  data: TagCountQuery;
}) => (
  <Layout>
    <Helmet>
      <title>{site?.siteMetadata?.title}</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>Tags</h1>
    <ul className="ui list grey-link">
      {group.map(tag => (
        <li key={tag.fieldValue}>
          <Link to={`/tags/${tag.fieldValue}/`}>
            {tag.fieldValue} ({tag.totalCount})
          </Link>
        </li>
      ))}
    </ul>
  </Layout>
);

export const pageQuery = graphql`
  query TagCount {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

export default Tags;
